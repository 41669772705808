import React, {useEffect} from 'react';
import {banner1, banner2, banner3, banner4, banner5, banner6} from 'assets/images';

export default function Banner(props) {
    const {activeBanner, setActiveBanner} = props;
    const banners = [banner1, banner2, banner3, banner4, banner5, banner6];

    useEffect(() => {
        const bannerSliderInterval = setInterval(() => {
            const nextActiveBanner = activeBanner + 1 >= banners.length ? 0 : activeBanner + 1;
            setActiveBanner(nextActiveBanner);
        }, 5000);

        return () => clearInterval(bannerSliderInterval);
    }, [activeBanner, setActiveBanner, banners.length]);

    return (
        <div className='product-list__banner'>
            <div className='banner-indicators d-flex gap-3'>
                {banners.map((banner, idx) => (
                    <button
                        className={`${idx === activeBanner ? 'active' : ''}`}
                        onClick={() => setActiveBanner(idx)}
                        key={banner.img}
                    />
                ))}
            </div>
            <div className='banner-inner container-fluid p-0'>
                <div className='row m-0 p-0 flex-nowrap h-100'>
                    {banners.map((banner, idx) => (
                        <div
                            key={idx}
                            className='banner-inner__item col-12 p-0 h-100'
                            style={{transform: `translateX(-${activeBanner * 100}%)`}}>
                            <img src={banner} className='d-block w-100 h-100' alt='banners' />
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
}
