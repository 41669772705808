const i18nLabels = {
    vi: {
        username: 'Vo Dinh Thien',
        useremail: 'vodinhthien@gmail.com',
        login: 'Đăng nhập',
        signup: 'Đăng ký',
        firstLetter: 'DEAL',
        title: 'SỐC MỖI NGÀY',
        hotdealsPrice: '₫{price}',
        productCategoryLabel: 'Danh mục sản phẩm',
        readAll: 'Xem tất cả'
    },
    en: {
        productCategoryLabel: 'Product Category',
        readAll: 'Read All'
    }
};

export default i18nLabels;
