import React from 'react';
import {Link} from 'react-router-dom';
import {BsCart2} from 'react-icons/bs';
import {MdMenu} from 'react-icons/md';
import {searchIcon, searchDocIcon, sapatcoLogo} from 'assets/images';

const HeaderMainView = (props) => {
    const {isLogin, setIsLogin, showCategoryModal, intl} = props;

    return (
        <>
            {isLogin && (
                <>
                    <div className='d-none d-md-block d-xl-block d-xxl-block'>
                        <div className='header'>
                            <div className='header-top'>
                                <div className='header-top-container'>
                                    <div className='header-top-container-left'>
                                        <Link to='/'>
                                            <img src={sapatcoLogo} alt='sapatco-logo' className='logo-img'></img>
                                        </Link>
                                        <div className='header-top-container-left-search'>
                                            <div className='w-22 padding-l-80'></div>
                                            <div className='search'>
                                                <div className='search-by-text'>
                                                    <form className='form-search'>
                                                        <input
                                                            className='input-search-header'
                                                            placeholder={intl.messages.searchLabel}></input>
                                                        <div className='search-logo'>
                                                            <img src={searchIcon} alt='search-logo'></img>
                                                        </div>
                                                    </form>
                                                </div>
                                                <div className='search-by-document-container'>
                                                    <button className='search-by-document'>
                                                        {intl.messages.searchForDocLabel}
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='header-top-container-right'>
                                        <div className='cart-icon'>
                                            <div className='cart-icon-container'>
                                                <span>
                                                    <Link to='/cart'>
                                                        <BsCart2 className='bs-cart-icon' />
                                                    </Link>
                                                    <div className='cart-number'>2</div>
                                                </span>
                                            </div>
                                        </div>
                                        <div className='header-user'>
                                            <div className='header-user-container'>
                                                <span
                                                    className='header-user-container-hello'
                                                    onClick={() => {
                                                        setIsLogin(false);
                                                    }}>
                                                    {intl.messages.userName}
                                                </span>
                                                <img
                                                    src='https://sapatco.com/public/avatars/users/23042021013858212648856YIO5MGLVU.jpeg'
                                                    alt='user-avatar'
                                                    className='user-img'></img>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='d-none d-sm-block d-md-none'>
                        <div className='header-mobile'>
                            <div className='header-mobile-container-top'>
                                <Link to='/'>
                                    <img src={sapatcoLogo} alt='sapatco-logo'></img>
                                </Link>
                                <div className='header-top-container-right-login'>
                                    <div className='cart-icon'>
                                        <div className='cart-icon-container'>
                                            <span>
                                                <Link to='/cart'>
                                                    <BsCart2 className='bs-cart-icon' />
                                                </Link>
                                                <div className='cart-number'>2</div>
                                            </span>
                                        </div>
                                    </div>
                                    <div className='header-user'>
                                        <div className='header-user-container'>
                                            <span
                                                className='header-user-container-hello'
                                                onClick={() => {
                                                    setIsLogin(false);
                                                }}>
                                                {intl.messages.userName}
                                            </span>
                                            <img
                                                src='https://sapatco.com/public/avatars/users/23042021013858212648856YIO5MGLVU.jpeg'
                                                alt='user-avatar'
                                                className='user-img'></img>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='header-mobile-container-bottom'>
                                <MdMenu className='header-mobile-menu' onClick={showCategoryModal} />
                                <div className='search-mobile'>
                                    <div className='search'>
                                        <div className='search-by-text'>
                                            <form className='form-search'>
                                                <input
                                                    className='input-search-header'
                                                    placeholder={intl.messages.searchLabel}></input>
                                                <div className='search-logo'>
                                                    <img src={searchIcon} alt='search-logo'></img>
                                                </div>
                                            </form>
                                        </div>
                                        <div className='search-by-document-container'>
                                            <button className='search-by-document'>
                                                {intl.messages.searchForDocLabel}
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='d-block d-sm-none'>
                        <div className='header-mobile'>
                            <div className='header-mobile-container-top'>
                                <Link to='/'>
                                    <img src={sapatcoLogo} alt='sapatco-logo'></img>
                                </Link>
                                <div className='header-top-container-right-login'>
                                    <div className='cart-icon'>
                                        <div className='cart-icon-container'>
                                            <span>
                                                <Link to='/cart'>
                                                    <BsCart2 className='bs-cart-icon' />
                                                </Link>
                                                <div className='cart-number'>2</div>
                                            </span>
                                        </div>
                                    </div>
                                    <div className='header-user'>
                                        <div className='header-user-container'>
                                            <span
                                                className='header-user-container-hello'
                                                onClick={() => {
                                                    setIsLogin(false);
                                                }}>
                                                {intl.messages.userName}
                                            </span>
                                            <img
                                                src='https://sapatco.com/public/avatars/users/23042021013858212648856YIO5MGLVU.jpeg'
                                                alt='user-avatar'
                                                className='user-img'></img>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='header-mobile-container-bottom'>
                                <MdMenu className='header-mobile-menu' />
                                <div className='search-mobile'>
                                    <div className='search'>
                                        <div className='search-by-text'>
                                            <form className='form-search'>
                                                <input
                                                    className='input-search-header'
                                                    placeholder={intl.messages.searchLabel}></input>
                                                <div className='search-logo'>
                                                    <img src={searchIcon} alt='search-logo'></img>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='search-by-document-mobile'>
                                <div className='search-by-document-mobile-container'>
                                    <img src={searchDocIcon} alt=''></img>
                                    <span className='search-doc-mobile'>{intl.messages.searchForDocLabel}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            )}
            {!isLogin && (
                <>
                    <div className='d-none d-md-block'>
                        <div className='header'>
                            <div className='header-top'>
                                <div className='header-top-container'>
                                    <div className='header-top-container-left'>
                                        <Link to='/'>
                                            <img src={sapatcoLogo} alt='sapatco-logo'></img>
                                        </Link>
                                        <div className='header-top-container-left-search'>
                                            <div className='w-22 padding-l-80'></div>
                                            <div className='search'>
                                                <div className='search-by-text'>
                                                    <form className='form-search'>
                                                        <input
                                                            className='input-search-header'
                                                            placeholder={intl.messages.searchLabel}></input>
                                                        <div className='search-logo'>
                                                            <img src={searchIcon} alt='search-logo'></img>
                                                        </div>
                                                    </form>
                                                </div>
                                                <div className='search-by-document-container'>
                                                    <button className='search-by-document'>
                                                        {intl.messages.searchForDocLabel}
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='header-top-container-right-not-login'>
                                        <div className='header-userNotLogin'>
                                            <span
                                                className='header-user-login'
                                                onClick={() => {
                                                    setIsLogin(true);
                                                }}>
                                                {' '}
                                                Đăng nhập &nbsp; | &nbsp;
                                            </span>
                                            <span className='header-user-register'>Đăng ký</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='d-none d-sm-block d-md-none '>
                        <div className='header-mobile'>
                            <div className='header-mobile-container-top '>
                                <Link to='/'>
                                    <img src={sapatcoLogo} alt='sapatco-logo'></img>
                                </Link>
                                <div className='header-top-container-right-not-login'>
                                    <div className='header-userNotLogin'>
                                        <span
                                            className='header-user-login'
                                            onClick={() => {
                                                setIsLogin(true);
                                            }}>
                                            {' '}
                                            Đăng nhập &nbsp; | &nbsp;
                                        </span>
                                        <span className='header-user-register'>Đăng ký</span>
                                    </div>
                                </div>
                            </div>
                            <div className='header-mobile-container-bottom'>
                                <MdMenu className='header-mobile-menu' onClick={showCategoryModal} />
                                <div className='search-mobile'>
                                    <div className='search'>
                                        <div className='search-by-text'>
                                            <form className='form-search'>
                                                <input
                                                    className='input-search-header'
                                                    placeholder={intl.messages.searchLabel}></input>
                                                <div className='search-logo'>
                                                    <img src={searchIcon} alt='search-logo'></img>
                                                </div>
                                            </form>
                                        </div>
                                        <div className='search-by-document-container'>
                                            <button className='search-by-document'>
                                                {intl.messages.searchForDocLabel}
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='d-block d-sm-none'>
                        <div className='header-mobile'>
                            <div className='header-mobile-container-top '>
                                <Link to='/'>
                                    <img src={sapatcoLogo} alt='sapatco-logo'></img>
                                </Link>
                                <div className='header-top-container-right-not-login'>
                                    <div className='header-userNotLogin'>
                                        <span
                                            className='header-user-login'
                                            onClick={() => {
                                                setIsLogin(true);
                                            }}>
                                            {' '}
                                            Đăng nhập &nbsp; | &nbsp;
                                        </span>
                                        <span className='header-user-register'>Đăng ký</span>
                                    </div>
                                </div>
                            </div>
                            <div className='header-mobile-container-bottom'>
                                <MdMenu className='header-mobile-menu' />
                                <div className='search-mobile'>
                                    <div className='search'>
                                        <div className='search-by-text'>
                                            <form className='form-search'>
                                                <input
                                                    className='input-search-header'
                                                    placeholder={intl.messages.searchLabel}></input>
                                                <div className='search-logo'>
                                                    <img src={searchIcon} alt='search-logo'></img>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='search-by-document-mobile'>
                                <div className='search-by-document-mobile-container'>
                                    <img src={searchDocIcon} alt=''></img>
                                    <span className='search-doc-mobile'>{intl.messages.searchForDocLabel}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            )}
        </>
    );
};

export default HeaderMainView;
