import React from 'react';

import {Maintenance} from 'assets/images';

export default function CategoryItem(props) {
    const {item, navigateTo} = props;
    const {href, label, icon = undefined} = item;
    const iconSrc = icon ? icon : Maintenance;

    return (
        <li className='category-menu__item d-flex align-items-center'>
            <img className='me-10px' src={iconSrc} alt={label} />
            <span onClick={() => navigateTo(href)}>{label}</span>
        </li>
    );
}
