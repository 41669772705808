import React from 'react';
import { FormattedMessage } from 'react-intl';
import FireLogo from 'assets/images/icon_24_fire.svg';
import Config from 'configuration';
import { pagePath } from 'configuration/routeConfig';

const HotDeal = (props) => {
    const { hotDealList, navigateTo, intl, i18nLabels } = props;
    return (
        <>
            <div className='product-list__hot-deal'>
                <div className='hot-deal-title'>
                    <img src={FireLogo} alt='fire-logo' className='fire-logo' />
                    <span className='hot-deal-title__first'>{intl.messages.firstLetter}</span>
                    <span> {intl.messages.title} </span>
                </div>
                <div className='hot-deal-container'>
                    {(hotDealList || []).map((item, idx) => {
                        return (
                            <div
                                key={`hotdeals-card-${idx}`}
                                className='hot-deal-card'

                                onClick={() =>
                                    navigateTo(`${pagePath.pdpUrl}/${item.id}/${item.name.replaceAll(' ', '-')}`)
                                }>
                                <span className={`hot-deal-card__sale-off ${item.sale_off === 0 ? 'none' : ''}`}>
                                    <div>-{item.sale_off}%</div>
                                </span>
                                <div className='hot-deal-card__img'>
                                    <img
                                        src={`${Config.apiConfig.endPoint}${item.default_image}`}
                                        alt='product-img'></img>
                                </div>
                                <div className='hot-deal-card__name'>{item.name}</div>
                                <div className='hot-deal-card__price'>
                                    <FormattedMessage
                                        {...i18nLabels.hotdealsPrice}
                                        values={{
                                            price: item.price
                                        }}
                                    />
                                </div>
                            </div>
                        );
                    })}
                </div>
            </div>
        </>
    );
};

export default HotDeal;
