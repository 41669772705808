import React, {Fragment} from 'react';

export default function FooterBlock(props) {
    const {title, data} = props;

    return (
        <div className='footer__block'>
            <div className='title-label'>{title}</div>
            <div className='content'>
                {data.map((info) => (
                    <Fragment key={info.title}>
                        {info.href ? (
                            <a href={info.href}>{info.title}</a>
                        ) : (
                            <span className={info.isHighlight ? 'fw-bold' : ''}>{info.title}</span>
                        )}
                    </Fragment>
                ))}
            </div>
        </div>
    );
}
