import banner1 from './png/img_banner_1.png';
import banner2 from './png/img_banner_2.png';
import banner3 from './png/img_banner_3.png';
import banner4 from './png/img_banner_4.png';
import banner5 from './png/img_banner_5.png';
import banner6 from './png/img_banner_6.png';
import TripleGears from './png/tripleGears.png';
import FemaleWorker from './png/femaleWorker.png';
import GooglePlay from './png/google-play.png';
import LogoFooter from './png/Logo_Footer_desktop.png';
import Avatar from './png/avatar.png';
import AppStore from './png/app-store.png';
import sapatcoLogo from './png/sapatco-logo.png';

import ChevronRight from './svg/chevron-right.svg';
import Facebook from './svg/facebook.svg';
import Gear from './svg/gear.svg';
import Instagram from './svg/instagram.svg';
import Linkedin from './svg/linkedin.svg';
import Maintenance from './svg/maintenance.svg';
import Twitter from './svg/twitter.svg';
import Youtube from './svg/youtube.svg';
import searchIcon from './svg/search.svg';
import searchDocIcon from './svg/icon-doc-search.svg';

export {
    banner1,
    banner2,
    banner3,
    banner4,
    banner5,
    banner6,
    TripleGears,
    FemaleWorker,
    GooglePlay,
    LogoFooter,
    Avatar,
    AppStore,
    ChevronRight,
    Facebook,
    Gear,
    Instagram,
    Linkedin,
    Maintenance,
    Twitter,
    Youtube,
    sapatcoLogo,
    searchIcon,
    searchDocIcon
};
