import React from 'react';
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom';
import ReactDOM from 'react-dom/client';
import './_styles.scss';
import Header from 'components/Header';
import Footer from 'components/Footer';
import { redirectionRuleList, routeList } from 'configuration/routeConfig';
import { Provider } from 'react-redux';
import store from 'app/store';
import Category from 'components/Category';
import ModalBackdrop from 'components/ModalBackdrop';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <Provider store={store}>
        <Router>
            <Category tablet />
            <Header />
            <Switch>
                {redirectionRuleList.map((rule, idx) => (
                    <Redirect key={`redirect-${idx}`} exact from={rule.from} to={rule.to} />
                ))}
                {routeList.map((route, idx) => (
                    <Route key={`route-${idx}`} exact path={route.path} component={route.component} />
                ))}
            </Switch>
            <Footer />
            <ModalBackdrop />
        </Router>
    </Provider>
);
