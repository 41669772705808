import React from 'react';
import {useDispatch} from 'react-redux';
import AuthContainer from './AuthContainer';

export default function Auth(props) {
    const dispatch = useDispatch();

    const authProps = {
        ...props,
        dispatch
    };
    return <AuthContainer {...authProps} />;
}
