export default function propsProvider(props) {
    const {
        intl,
        toggleShowAllCategories,
        isShowCategoryExtend,
        navigateTo,
        scrollTop,
        category,
        auth,
        hideCategoryModal,
        tablet
    } = props;

    const {isLogin} = auth;
    const {isShowCategory, data} = category.details;

    return {
        intl,
        toggleShowAllCategories,
        isShowCategoryExtend,
        isShowCategory,
        isLogin,
        navigateTo,
        scrollTop,
        categoryList: data,
        hideCategoryModal,
        tablet
    };
}
