import {configureStore} from '@reduxjs/toolkit';
import productListReducer from 'features/PLP/productListSlice';
import productDetailsReducer from 'features/PDP/productDetailsSlice';
import authReducer from 'features/Auth/authSlice';
import footerReducer from 'components/Footer/footerSlice';
import categoryReducer from 'components/Category/categorySlice';
import modalBackdropReducer from 'components/ModalBackdrop/modalBackdropSlice';

const rootReducer = {
    productList: productListReducer,
    productDetails: productDetailsReducer,
    auth: authReducer,
    footer: footerReducer,
    category: categoryReducer,
    modalBackdrop: modalBackdropReducer
};
const store = configureStore({reducer: rootReducer});
export default store;
