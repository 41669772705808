import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import productApi from 'api/productApi';
import Config from 'configuration';

const initialProductList = () => {
    if (sessionStorage.getItem(Config.storageKey.productList)) {
        return {...JSON.parse(sessionStorage.getItem(Config.storageKey.productList))};
    }
    return {
        hotDealDetails: {}
    };
};

export const getHotDeals = createAsyncThunk('productList/hotdeals', async (params) => {
    const res = await productApi.getHotDeals(params);
    return res;
});

const getHotDealsSuccess = (res) => {
    const modColletion = res.data.collection.map((item) => {
        if (item.origin_price === 0) {
            return {
                ...item,
                sale_off: 0
            };
        }
        return {
            ...item,
            sale_off: Math.round(100 - (item.price / item.origin_price) * 100)
        };
    });
    return {
        ...res,
        data: {
            ...res.data,
            collection: modColletion
        }
    };
};

const productList = createSlice({
    name: 'productList',
    initialState: initialProductList(),
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getHotDeals.fulfilled, (state, action) => {
                state.hotDealDetails = getHotDealsSuccess(action.payload);
                sessionStorage.setItem(Config.storageKey.productList, JSON.stringify(state));
            })
            .addCase(getHotDeals.rejected, (state, action) => {
                console.log({state, action});
            });
    }
});

const {reducer, actions} = productList;
export const {addProduct, removeProduct, updateProduct} = actions;
export default reducer;
