import {toggleCategory} from 'components/Category/categorySlice';
import React from 'react';
import propsProvider from './modalBackdropPropsProvider';
import {toggleModalBackdrop} from './modalBackdropSlice';
import ModalBackdropMainView from './template/ModalBackdropMainView';

const ModalBackdropContainer = (props) => {
    const {dispatch, modalBackdrop} = props;

    const hideModalBackdrop = () => {
        dispatch(toggleCategory(false));
        dispatch(toggleModalBackdrop(false));
        document.body.style.overflow = 'auto';
    };

    const modalBackdropProps = {
        hideModalBackdrop,
        modalBackdrop
    };

    return <ModalBackdropMainView {...propsProvider(modalBackdropProps)} />;
};

export default ModalBackdropContainer;
