import React, {useEffect} from 'react';
import {useState} from 'react';
import {useIntl} from 'react-intl';
import useScrollTop from 'utils/hooks/useScrollTop';
import propsProvider from './categoryPropsProvider';
import {getCategories} from './categorySlice';
import CategoryMainView from './template/CategoryMainView';

const CategoryContainer = (props) => {
    const {dispatch, history, category, auth, tablet} = props;

    const intl = useIntl();
    const scrollTop = useScrollTop();
    const [isShowCategoryExtend, setIsShowCategoryExtend] = useState(false);

    const toggleShowAllCategories = () => setIsShowCategoryExtend(!isShowCategoryExtend);
    const navigateTo = (path) => {
        history.push(path);
    };

    useEffect(() => {
        dispatch(getCategories());
    }, []);

    const categoryContainerProps = {
        intl,
        toggleShowAllCategories,
        isShowCategoryExtend,
        navigateTo,
        scrollTop,
        category,
        auth,
        tablet
    };

    return <CategoryMainView {...propsProvider(categoryContainerProps)} />;
};

export default CategoryContainer;
