import axiosClient from './axiosClient';

const footerApi = {
    getFooterData: () => {
        const url = '/api/footerData';
        return axiosClient.get(url);
    }
};

export default footerApi;
