import React from 'react';
import {authSelector, categorySelector} from 'app/selectors';
import DecorateContainer from 'components/DecorateContainer';
import {useDispatch, useSelector} from 'react-redux';
import {useHistory} from 'react-router-dom';
import CategoryContainer from './CategoryContainer';
import i18nLabels from './category.i18n';

const Category = (props) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const auth = useSelector(authSelector);
    const category = useSelector(categorySelector);

    const categoryProps = {
        ...props,
        dispatch,
        history,
        auth,
        category,
        i18nLabels
    };

    return <DecorateContainer childComponent={CategoryContainer} {...categoryProps} />;
};

export default Category;
