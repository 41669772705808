const i18nLabels = {
    vi: {
        brand: 'Từ hãng',
        SKUproduct: 'SKU sản phẩm',
        quantity: 'Số lượng',
        product: 'sản phẩm',
        productLeft: 'Còn',
        requestPrice: 'Yêu cầu báo giá',
        orderNow: 'Đặt hàng ngay',
        addToCart: 'Thêm vào giỏ hàng',
        saving: 'Tiết kiệm',
        overviewDescription: 'Mô tả tổng quan sản phẩm',
        zoomImage: 'Rê vào hình để phóng to'
    },
    en: {}
};

export default i18nLabels;
