import Config from 'configuration';
import React, { useEffect, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { checkOverflow } from 'utils/checkOverflow';
import propsProvider from './productDetailsPropsProvider';
import { getProductDetails } from './productDetailsSlice';
import ProductDetailsMainView from './template/ProductDetailsMainView';

export default function ProductDetailsContainer(props) {
    const { dispatch, i18nLabels, auth, history, productId, productDetails } = props;
    const [quantityDemand, setQuantityDemand] = useState(1);
    const [quantitySupply, setQuantitySupply] = useState(0);
    const [groupType, setGroupType] = useState([]);
    const [groupPrice, setGroupPrice] = useState({ price: 0, origin_price: 0 });
    const [productImgs, setProductImgs] = useState([]);
    const [previewImgCoord, setPreviewImgCoord] = useState({ x: 0, y: 0 });
    const [isPreviewingImg, setIsPreviewingImg] = useState(false);
    const [isImgListOverflowing, setIsImgListOverflowing] = useState(false);
    const imgListRef = useRef(null);
    const intl = useIntl();

    const productInfo = productDetails.productInfo.data;
    const { endPoint } = Config.apiConfig;
    const imgPreviewScale = 2;
    const imgPreviewLength = 280;

    const quantityDecrement = () => setQuantityDemand(quantityDemand <= 1 ? 1 : quantityDemand - 1);
    const quantityIncrement = () => setQuantityDemand(quantityDemand + 1);
    const navigateTo = (path) => history.push(path);
    const handleScrollLeft = (el, offset) => el.scrollLeft += offset;
    const handleScrollRight = (el, offset) => el.scrollLeft -= offset;
    const handleGroupType = (name, id) => {
        const newGroupType = groupType.map((group) => {
            if (name === group.name) {
                return {
                    name,
                    id
                };
            }

            return group;
        });

        setGroupType(newGroupType);
    };
    const handleProductViewedImage = (url) => {
        const newProductImgs = productImgs.map((img) => {
            img.isActive = url === img.url;

            return img;
        });

        setProductImgs(newProductImgs);
    };
    const handlePreviewImage = (e) => {
        setIsPreviewingImg(true);
        const { offsetX, offsetY } = e.nativeEvent;
        const relativeX = getRealCoordinate(offsetX - imgPreviewLength),
            relativeY = getRealCoordinate(offsetY - imgPreviewLength);

        setPreviewImgCoord({ x: relativeX, y: relativeY });
    };
    const getRealCoordinate = (coord) => {
        if (coord >= 50) return 50;
        if (coord <= 0) return 0;
        return coord;
    };

    useEffect(() => {
        dispatch(getProductDetails(productId));
    }, []);

    useEffect(() => {
        if (productInfo) {
            const { extend_type, extend_images } = productInfo;

            if (extend_type) {
                const productGroupType = extend_type.map((type) => {
                    return {
                        name: type.group_name,
                        id: type.types[0].id
                    };
                });
                setGroupType(productGroupType);
            }

            const productImgs = extend_images
                .slice()
                .sort((first, second) => first.sort_order - second.sort_order)
                .map((img, idx) => {
                    return {
                        url: `${endPoint}${img.physical_path}`,
                        isActive: idx === 0
                    };
                });
            setProductImgs(productImgs);
        }
    }, [productInfo, endPoint]);

    useEffect(() => {
        if (productInfo) {
            const { extend_product_type, quantity, price, origin_price } = productInfo;

            if (groupType.length > 0) {
                for (const product_type of extend_product_type) {
                    const { type_id, quantity, price, origin_price } = product_type;
                    let isMatchedId = true;
                    for (let i = 0; i < type_id.length && isMatchedId; i++) {
                        if (type_id[i] !== groupType[i].id) isMatchedId = false;
                    }

                    if (isMatchedId) {
                        setQuantitySupply(quantity);
                        setGroupPrice({ price, origin_price });
                    }
                }
            } else {
                setQuantitySupply(quantity);
                setGroupPrice({ price, origin_price });
            }
        }
    }, [productInfo, groupType]);

    useEffect(() => {
        const handleImgListOverflow = () => {
            setIsImgListOverflowing(checkOverflow(imgListRef.current));
        };

        window.addEventListener('resize', handleImgListOverflow);

        return () => window.removeEventListener('resize', handleImgListOverflow);
    });

    const productDetailsContainerProps = {
        dispatch,
        i18nLabels,
        auth,
        navigateTo,
        productInfo,
        quantityIncrement,
        quantityDecrement,
        quantityDemand,
        quantitySupply,
        groupType,
        handleGroupType,
        groupPrice,
        productImgs,
        handleProductViewedImage,
        handlePreviewImage,
        isPreviewingImg,
        setIsPreviewingImg,
        previewImgCoord,
        imgPreviewScale,
        imgPreviewLength,
        imgListRef,
        handleScrollLeft,
        handleScrollRight,
        isImgListOverflowing,
        intl
    };

    return <ProductDetailsMainView {...propsProvider(productDetailsContainerProps)} />;
}
