import axiosClient from './axiosClient';

const authApi = {
    authenticate: (data) => {
        const url = '/api/authenticate';
        return axiosClient.post(url, data, {params: data});
    }
};

export default authApi;
