import React from 'react';
import Auth from 'features/Auth';
import Banner from './subViews/Banner/Banner';
import Category from 'components/Category';
import HotDeal from './subViews/HotDeal/HotDeal';

export default function ProductListMainView(props) {
    const {bannerProps, hotDealProps} = props;

    return (
        <div className='product-list'>
            <div className='position-relative position-sm-static d-flex'>
                <Category />
                <Banner {...bannerProps} />
            </div>
            <Auth />
            <HotDeal {...hotDealProps} />
        </div>
    );
}
