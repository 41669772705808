import React from 'react';

const ModalBackdropMainView = (props) => {
    const {hideModalBackdrop, isShowModalBackdrop = false} = props;

    return (
        <div className={`modal-backdrop ${isShowModalBackdrop ? 'show-modal' : ''}`} onClick={hideModalBackdrop}></div>
    );
};

export default ModalBackdropMainView;
