import PLP from 'features/PLP';
import PDP from 'features/PDP';
import Cart from 'features/Cart';
import NotFound from 'components/NotFound';

const plpUrl = '/productlist';
const pdpUrl = '/productdetails';
const cartUrl = '/cart';
const rootUrl = '/';
const notFoundUrl = '/*';

const pdpUrlMatch = `${pdpUrl}/:productId/:productName`;

export const routeList = [
    { path: plpUrl, component: PLP },
    { path: pdpUrlMatch, component: PDP },
    { path: cartUrl, component: Cart },
    // Keep "Not found page" the last one
    { path: rootUrl, component: PLP },
    { path: notFoundUrl, component: NotFound }
];

export const redirectionRuleList = [{ from: rootUrl, to: plpUrl }];

export const pagePath = {
    plpUrl,
    pdpUrl,
    cartUrl,
    rootUrl
};
