import axiosClient from './axiosClient';

const productApi = {
    getHotDeals: (params) => {
        const url = '/api/products/hotdeals';
        return axiosClient.get(url, {params});
    },

    getProductDetails: (id) => {
        const url = '/api/products';
        return axiosClient.get(`${url}/${id}`);
    }
};

export default productApi;
