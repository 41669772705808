import {createSlice} from '@reduxjs/toolkit';

const modalBackdrop = createSlice({
    name: 'modalBackdrop',
    initialState: {
        isShowModalBackdrop: false
    },
    reducers: {
        toggleModalBackdrop: (state, action) => {
            state.isShowModalBackdrop = action.payload;
        }
    },
    extraReducers: {}
});

const {reducer, actions} = modalBackdrop;
export const {toggleModalBackdrop} = actions;
export default reducer;
