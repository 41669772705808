export const productListSelector = (state) => state.productList;

export const productDetailsSelector = (state) => state.productDetails;

export const authSelector = (state) => state.auth;

export const categorySelector = (state) => state.category;

export const modalBackdropSelector = (state) => state.modalBackdrop;

export const footerSelector = (state) => state.footer;
