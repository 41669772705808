import {toggleCategory} from 'components/Category/categorySlice';
import {setLang} from 'components/Footer/footerSlice';
import {toggleModalBackdrop} from 'components/ModalBackdrop/modalBackdropSlice';
import React, {useState} from 'react';
import {useIntl} from 'react-intl';
import propsProvider from './headerPropsProvider';
import HeaderMainView from './template/HeaderMainView';

const HeaderContainer = (props) => {
    const {dispatch} = props;

    const [isLogin, setIsLogin] = useState(false);
    const intl = useIntl();
    const changeLanguage = (locale) => {
        dispatch(setLang(locale));
    };
    const showCategoryModal = () => {
        dispatch(toggleCategory(true));
        dispatch(toggleModalBackdrop(true));
        document.body.style.overflow = 'hidden';
    };

    const headerProps = {
        ...props,
        intl,
        changeLanguage,
        isLogin,
        setIsLogin,
        showCategoryModal
    };

    return <HeaderMainView {...propsProvider(headerProps)} />;
};

export default HeaderContainer;
