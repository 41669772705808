import React, {Fragment} from 'react';

import {LogoFooter} from 'assets/images';

import FooterBlock from './FooterBlock';

export default function FooterMainView(props) {
    const {changeLanguage, intl, locale, contactInfo, appLink, terms, policies, contactUs} = props;

    const footerInfoData = [
        {
            title: intl.messages.contactLabel,
            data: [
                {title: contactInfo.CompanyName, isHighlight: false},
                {title: contactInfo.CompanyAddress, isHighlight: false},
                {title: contactInfo.Hotline, isHighlight: true},
                {title: contactInfo.Email, isHighlight: true}
            ]
        },
        {
            title: intl.messages.serviceLabel,
            data: [
                {title: intl.messages.serviceSearchByDoc, href: 'abc'},
                {title: intl.messages.serviceMaintenance, href: 'abc'},
                {title: intl.messages.serviceAccessary, href: 'abc'}
            ]
        },
        {
            title: intl.messages.repairLabel,
            data: [
                {title: intl.messages.repairForklift, href: 'abc'},
                {title: intl.messages.repairBattery, href: 'abc'},
                {title: intl.messages.repairPump, href: 'abc'},
                {title: intl.messages.repairInjector, href: 'abc'}
            ]
        },
        {
            title: intl.messages.downloadLabel,
            data: [
                {title: intl.messages.downloadCatalogues, href: 'abc'},
                {title: intl.messages.downloadBrochures, href: 'abc'},
                {title: intl.messages.downloadRepairDoc, href: 'abc'},
                {title: intl.messages.downloadVideo, href: 'abc'}
            ]
        },
        {
            title: intl.messages.cooperationLabel,
            data: [
                {title: intl.messages.cooperationSupply, href: 'abc'},
                {title: intl.messages.cooperationSelling, href: 'abc'}
            ]
        }
    ];

    const langInfo = [
        {id: 'vi', title: intl.messages.langVietnam},
        {id: 'en', title: intl.messages.langEnglish}
    ];

    const socialNetworksInfo = Object.entries(contactUs).map(([key, value]) => value);
    const appInfo = Object.entries(appLink).map(([key, value]) => value);
    const privacyInfo = [policies, terms];

    return (
        <footer className='footer'>
            <div className='container-fluid p-0'>
                <div className='footer__content'>
                    <div className='row'>
                        {footerInfoData.map((info, idx) => (
                            <div
                                key={info.title}
                                className={`${idx === 0 ? 'col-12 col-md-3' : 'col-6 col-md-2 mt-0 mt-sm-20px'}`}>
                                <FooterBlock title={info.title} data={info.data} />
                            </div>
                        ))}
                    </div>
                </div>

                <div className='footer__content footer__map mt-15px mt-xs-25px'>
                    <div className='row'>
                        <div className='col-12 col-sm-6 col-md-5 col-lg-3'>
                            <iframe
                                src={contactInfo.MapLink}
                                allowFullScreen=''
                                loading='lazy'
                                referrerPolicy='no-referrer-when-downgrade'
                                title='map'
                                className='map-company'
                            />
                        </div>

                        <div className='footer__app col mt-xs-20px'>
                            <div className='title-label'>{intl.messages.appDownload}</div>

                            <div className='content mt-xs-20px'>
                                {appInfo.map((app, idx) => (
                                    <a href={app.Link} key={app.Label}>
                                        <img
                                            src={app.Image}
                                            alt={app.Label}
                                            width={120}
                                            className={idx !== 0 ? 'mt-xs-15px' : ''}
                                        />
                                    </a>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>

                <div className='footer__content footer__logo mt-25px d-flex align-items-center'>
                    <div className='row align-items-center'>
                        <div className='col-6 col-md-3'>
                            <a href='/homepage'>
                                <img src={LogoFooter} alt='logo' width={150} className='logo p-sm-0' />
                            </a>
                        </div>

                        <div className='footer__lang text-center text-md-start col-md-2 col-6'>
                            {langInfo.map((info, idx) => (
                                <Fragment key={info.title}>
                                    <button
                                        className={`cursor-pointer border-0 ${info.id === locale ? 'fw-bold' : ''}`}
                                        onClick={() => changeLanguage(info.id)}>
                                        {info.title}
                                    </button>
                                    {langInfo.length - 1 !== idx && <span> | </span>}
                                </Fragment>
                            ))}
                        </div>

                        <div className='footer__extends mt-sm-15px col-12 col-md-7 d-flex align-items-center'>
                            <div className='row align-items-center p-0'>
                                <div className='social-networks d-flex align-items-center col-6 p-0 gap-3'>
                                    <div className='d-none d-sm-block fw-bold'>{intl.messages.socialNetworkLabel}</div>

                                    <div className='d-flex flex-wrap align-items-center gap-2'>
                                        {socialNetworksInfo.map((info) => (
                                            <a href={info.Link} key={info.Label}>
                                                <img src={info.Image} alt={info.Label} />
                                            </a>
                                        ))}
                                    </div>
                                </div>

                                <div className='footer__quote text-md-start text-center col fw-bold'>
                                    {intl.messages.quoteLabel}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='footer__content footer__copyright mt-sm-25px flex-column'>
                    <div className='row mt-md-2 mt-0'>
                        <div className='mb-2'>{intl.messages.copyrightLabel}</div>
                        <div className='d-flex justify-content-between align-items-start align-items-md-center flex-column flex-md-row'>
                            <div>{intl.messages.privacyLabel}</div>
                            <div className='footer-privacy__links mt-sm-25px d-flex justify-content-between'>
                                {privacyInfo.map((info) => (
                                    <a href={info.Link} key={info.Label}>
                                        {info.Label}
                                    </a>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    );
}
