const i18nLabels = {
    vi: {
        contactLabel: 'Liên hệ',
        contactCompanyName: 'Công nghệ Phụ tùng Sài Gòn Sapatco',
        contactCompanyAddress:
            '57/15, Đường 7, Khu phố 5, Phường Hiệp Bình Phước, Thành phố Thủ Đức, Thành phố Hồ Chí Minh, Việt Nam.',
        contactCompanyPhone: 'Hotline: 0777 881 869',
        contactCompanyEmail: 'support@sapatco.com',
        serviceLabel: 'Sản phẩm & Dịch vụ',
        serviceSearchByDoc: 'Tìm phụ tùng theo tài liệu',
        serviceMaintenance: 'Dịch vụ bảo dưỡng xe nâng',
        serviceAccessary: 'Phụ tùng đã qua sử dụng',
        repairLabel: 'Sửa chữa và Phục hồi',
        repairForklift: 'Thợ sửa xe nâng',
        repairBattery: 'Phục hồi bình ắc quy xe nâng',
        repairPump: 'Phục hồi bơm thuỷ lực',
        repairInjector: 'Phục hồi kim phun nhiên liệu',
        downloadLabel: 'Tải xuống',
        downloadCatalogues: 'Catalogues',
        downloadBrochures: 'Brochures',
        downloadRepairDoc: 'Tài liệu bảo dưỡng',
        downloadVideo: 'video',
        cooperationLabel: 'Hợp tác & Liên kết',
        cooperationSupply: 'Các hãng cung cấp',
        cooperationSelling: 'Bán hàng cùng SAPATCO',
        appDownload: 'Tải ứng dụng trên điện thoại',
        langVietnam: 'Tiếng Việt',
        langEnglish: 'English',
        socialNetworkLabel: 'Kết nối với chúng tôi',
        quoteLabel: '"Nhanh hơn, Tốt hơn, Rẻ hơn"',
        copyrightLabel: '2022 © Bản quyền của Công Ty TNHH Công nghệ Phụ tùng Sài Gòn SAPATCO',
        privacyLabel:
            'Giấy chứng nhận kinh doanh số 0315229636 do sở Kế hoạch và Đầu tư Thành phố Hồ Chí Minh cấp ngày 18/09/2018',
        policyLabel: 'Chính sách Bảo mật',
        rulesLabel: 'Điều khoản & Điều kiện'
    },
    en: {
        contactLabel: 'Contact',
        serviceLabel: 'Products & Services',
        repairLabel: 'Repair & Recovery',
        downloadLabel: 'Download',
        cooperationLabel: 'Cooperation & Association'
    }
};

export default i18nLabels;
