import {modalBackdropSelector} from 'app/selectors';
import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import ModalBackdropContainer from './ModalBackdropContainer';

const ModalBackdrop = (props) => {
    const dispatch = useDispatch();
    const modalBackdrop = useSelector(modalBackdropSelector);

    const modalBackdropProps = {
        ...props,
        dispatch,
        modalBackdrop
    };

    return <ModalBackdropContainer {...modalBackdropProps} />;
};

export default ModalBackdrop;
