import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import categoryApi from 'api/categoryApi';
import Config from 'configuration';

const initialCategory = () => {
    if (sessionStorage.getItem(Config.storageKey.category)) {
        return {...JSON.parse(sessionStorage.getItem(Config.storageKey.category))};
    }
    return {
        details: {
            isShowCategory: false
        }
    };
};

export const getCategories = createAsyncThunk('categories', async (params) => {
    const res = await categoryApi.getCategories(params);
    return res;
});

const category = createSlice({
    name: 'category',
    initialState: initialCategory(),
    reducers: {
        toggleCategory: (state, action) => {
            state.details.isShowCategory = action.payload;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(getCategories.fulfilled, (state, action) => {
                state.details = action.payload;
                state.details.isShowCategory = false;
                sessionStorage.setItem(Config.storageKey.category, JSON.stringify(state));
            })
            .addCase(getCategories.rejected, (state, action) => {
                console.log({state, action});
            });
    }
});

const {reducer, actions} = category;
export const {toggleCategory} = actions;
export default reducer;
