import {useEffect, useState} from 'react';

export default function useScrollTop() {
    const [scrollTop, setScrollTop] = useState(0);

    useEffect(() => {
        const handleScroll = () => {
            setScrollTop(document.documentElement.scrollTop);
        };

        window.addEventListener('scroll', handleScroll);
        handleScroll();

        return () => window.removeEventListener('scroll', handleScroll);
    });

    return scrollTop;
}
