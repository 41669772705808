import React from 'react';
import BriefDetail from './subViews/BriefDetail/BriefDetail';

export default function ProductDetailsMainView(props) {
    const { briefDetailProps } = props;

    return (
        <div className='product-details'>
            <div className='product-details__info'>
                <BriefDetail {...briefDetailProps} />
            </div>
        </div>
    );
}
