export default function propsProvider(props) {
    /*
        - To only list utilized props
        - Wrapping utilized props on each view
    */
    const {intl, i18nLabels, hotDealList, navigateTo, activeBanner, setActiveBanner} = props;

    return {
        navigateTo,
        i18nLabels,
        hotDealProps: {
            intl,
            i18nLabels,
            navigateTo,
            hotDealList
        },
        bannerProps: {
            intl,
            activeBanner,
            setActiveBanner
        }
    };
}
