export default function propsProvider(props) {
    const {
        intl,
        auth,
        navigateTo,
        productInfo,
        quantityDecrement,
        quantityIncrement,
        quantityDemand,
        quantitySupply,
        groupType,
        handleGroupType,
        groupPrice,
        productImgs,
        handleProductViewedImage,
        handlePreviewImage,
        isPreviewingImg,
        setIsPreviewingImg,
        previewImgCoord,
        imgPreviewScale,
        imgPreviewLength,
        imgListRef,
        handleScrollLeft,
        handleScrollRight,
        isImgListOverflowing,
    } = props;
    const { isLogin } = auth;
    const activeImage = productImgs.find((img) => img.isActive);
    const activeImageUrl = activeImage ? activeImage.url : '';

    return {
        briefDetailProps: {
            intl,
            navigateTo,
            productInfo,
            quantityIncrement,
            quantityDecrement,
            quantityDemand,
            quantitySupply,
            groupType,
            handleGroupType,
            isLogin,
            groupPrice,
            productImgs,
            handleProductViewedImage,
            handlePreviewImage,
            isPreviewingImg,
            setIsPreviewingImg,
            previewImgCoord,
            imgPreviewScale,
            imgPreviewLength,
            imgListRef,
            activeImageUrl,
            isImgListOverflowing,
            handleScrollLeft,
            handleScrollRight,
        }
    };
}