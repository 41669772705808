export default function propsProvider(props) {
    /*
		- To only list utilized props
		- Wrapping utilized props on each view
*/
    const {intl, changeLanguage, locale, isLogin, setIsLogin, showCategoryModal} = props;

    return {
        intl,
        changeLanguage,
        locale,
        isLogin,
        setIsLogin,
        showCategoryModal
    };
}
