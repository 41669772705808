import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import productApi from "api/productApi";

export const getProductDetails = createAsyncThunk('product/details', async (params) => {
	const res = await productApi.getProductDetails(params);
	return res;
});

const productDetails = createSlice({
	name: 'productDetails',
	initialState: {
		productInfo: {}
	},
	reducers: {},
	extraReducers: (builder) => {
		builder
			.addCase(getProductDetails.fulfilled, (state, action) => {
				state.productInfo = action.payload;
			})
			.addCase(getProductDetails.rejected, (state, action) => {
				console.log({ state, action });
			});
	}
});

const { reducer, actions } = productDetails;
export const { } = actions;
export default reducer;