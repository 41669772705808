import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import authApi from 'api/authApi';
import Config from 'configuration';

const initialAuth = () => {
    const auth = sessionStorage.getItem(Config.storageKey.auth);
    if (auth) {
        return {...JSON.parse(auth)};
    }
    return {};
};
export const authenticate = createAsyncThunk('auth/authenticate', async (data) => {
    const res = authApi.authenticate(data);
    return res;
});
const auth = createSlice({
    name: 'auth',
    initialState: initialAuth(),
    reducers: {},
    extraReducers: (buider) => {
        buider.addCase(authenticate.fulfilled, (state, action) => {
            state.token = action.payload.token;
            sessionStorage.setItem(Config.storageKey.auth, JSON.stringify(state));
        });
    }
});

const {reducer} = auth;
export default reducer;
