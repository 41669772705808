import React, {useEffect, useState} from 'react';
import {useIntl} from 'react-intl';
import propsProvider from './ProductListPropsProvider';
import {getHotDeals} from './productListSlice';
import ProductListMainView from './template/ProductListMainView';

export default function ProductListContainer(props) {
    /*-----TODO-----
- To declare state, props, func
- Call API
- To setup data for views
*/
    const intl = useIntl();
    const {dispatch, productList, i18nLabels, auth, history} = props;
    const [activeBanner, setActiveBanner] = useState(0);
    let hotDealList = [];

    if (productList.hotDealDetails.data) hotDealList = productList.hotDealDetails.data.collection;

    const navigateTo = (path) => {
        history.push(path);
    };

    useEffect(() => {
        const hotdealsParams = {
            Filters: 'is_hidden==false',
            Sorts: '',
            Page: 1,
            PageSize: 10
        };
        dispatch(getHotDeals(hotdealsParams));
    }, []);

    const productListContainerProps = {
        ...props,
        intl,
        navigateTo,
        hotDealList,
        dispatch,
        i18nLabels,
        auth,
        activeBanner,
        setActiveBanner
    };

    return <ProductListMainView {...propsProvider(productListContainerProps)} />;
}
