const i18nLabels = {
    vi: {
        searchForDocLabel: 'Tìm kiếm theo tài liệu',
        searchLabel: 'Tìm Tên phụ tùng, Mã phụ tùng, SKU...',
        userName: 'Hi, Võ Đình Thiên'
    },
    en: {
        searchForDocLabel: 'Search by document',
        searchLabel: 'Search part name, part code, SKU...',
        userName: 'Hi, Võ Đình Thiên'
    }
};

export default i18nLabels;
