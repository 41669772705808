import React from 'react';

import i18nLabels from './header.i18n';
import DecorateContainer from 'components/DecorateContainer';
import {useDispatch} from 'react-redux';
import HeaderContainer from './HeaderContainer';

export default function Header(props) {
    const dispatch = useDispatch();

    const headerProps = {
        ...props,
        i18nLabels,
        dispatch
    };

    return <DecorateContainer {...headerProps} childComponent={HeaderContainer} />;
}
