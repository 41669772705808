export default function propsProvider(props) {
    /*
            - To only list utilized props
            - Wrapping utilized props on each view
    */
    const {intl, changeLanguage, locale, footer} = props;
    const {contactInfo, appLink, terms, policies, contactUs, faq} = footer.data;

    return {
        intl,
        changeLanguage,
        locale,
        contactInfo,
        appLink,
        terms,
        policies,
        contactUs,
        faq
    };
}
