import React, {useEffect} from 'react';
import propsProvider from './AuthPropsProvider';
import {authenticate} from './authSlice';
import AuthMainView from './template/AuthMainView';

export default function AuthContainer(props) {
    const {dispatch} = props;
    useEffect(() => {
        const data = {
            username: 'vodinhthien@gmail.com',
            password: 'RAPtor@1234'
        };
        dispatch(authenticate(data));
    }, []);
    const authContainerProps = {
        ...props
    };
    return <AuthMainView {...propsProvider(authContainerProps)} />;
}
