import React, {Fragment} from 'react';

import {Avatar, ChevronRight} from 'assets/images';
import CategoryItem from './CategoryItem';
import Config from 'configuration';

export default function CategoryMainView(props) {
    const {
        intl,
        toggleShowAllCategories,
        isShowCategoryExtend,
        navigateTo,
        isLogin = false,
        isShowCategory = false,
        scrollTop = 0,
        categoryList = [],
        tablet = false
    } = props;
    const {endPoint} = Config.apiConfig;
    const productCategoryList = categoryList.map((item) => {
        return {
            label: item.name,
            href: 'searchbycategory',
            icon: item.image ? `${endPoint}${item.image}` : '',
            id: item.id
        };
    });

    return (
        <>
            {tablet ? (
                <div
                    className={`mobile-nav d-block d-md-none position-absolute ${isShowCategory ? 'showCategory' : ''}`}
                    style={{top: scrollTop}}>
                    <div className='mobile-nav__user w-100 d-flex align-items-center justify-content-between'>
                        <div className='user-info d-flex align-items-center gap-2'>
                            {isLogin ? (
                                <>
                                    <img src={Avatar} alt='avatar' />
                                    <div className='user-data d-flex flex-column'>
                                        <span className='user-data__username'>{intl.messages.username}</span>
                                        <span className='user-data__useremail'>{intl.messages.useremail}</span>
                                    </div>
                                </>
                            ) : (
                                <>
                                    <span>{intl.messages.login}</span>
                                    <span> | </span>
                                    <span>{intl.messages.signup}</span>
                                </>
                            )}
                        </div>
                        <div className='mobile-nav-arrow'>
                            <img src={ChevronRight} alt='chevron-right' />
                        </div>
                    </div>
                    <nav className='category w-100'>
                        <h4 className='mb-0'>{intl.messages.productCategoryLabel}</h4>

                        <ul className='category-menu m-0 p-0'>
                            {productCategoryList.map((item, idx) => (
                                <CategoryItem item={item} key={idx} />
                            ))}
                        </ul>
                    </nav>
                </div>
            ) : (
                <nav className='category d-none d-md-block'>
                    <h4 className='mb-0 ellipsis'>{intl.messages.productCategoryLabel}</h4>

                    <ul className='category-menu m-0 p-0'>
                        {productCategoryList.map((item, idx) => (
                            <Fragment key={item.id}>
                                {idx < 10 && <CategoryItem item={item} navigateTo={navigateTo} />}
                            </Fragment>
                        ))}

                        <li
                            className='category-menu__extend'
                            onMouseEnter={toggleShowAllCategories}
                            onMouseLeave={toggleShowAllCategories}>
                            <span>{intl.messages.readAll}</span>
                            {isShowCategoryExtend && (
                                <div className='container-fluid extend-panel d-flex flex-column flex-wrap'>
                                    {productCategoryList.map((item) => (
                                        <div key={item.id} className='extend-panel__item'>
                                            <span onClick={() => navigateTo(item.href)}>{item.label}</span>
                                        </div>
                                    ))}
                                </div>
                            )}
                        </li>
                    </ul>
                </nav>
            )}
        </>
    );
}
